import {useSearchParams} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import SignupFlow from '../flows/SignupFlow';
import { Card, Col, Row, Typography } from 'antd';
import {docsEndpoint} from '../data';
import { retrieveUserOrgs } from "../requests";
import {environment} from "../data";
const { Title } = Typography;
const { Meta } = Card;

function has_new_registree_parameter(params) {
  var retData = params.get("awsToken")
  console.log("has_new_registree_parameter, retData: ", retData)
  console.log("has_new_registree_parameter, res: ", !!retData)
  return !!retData
}

function get_registree_data(params) {
  var data = params.get("awsToken")
  console.log("has_new_registree_parameter, retData: ", data)
  console.log("has_new_registree_parameter, res: ", !!data)
  return data
}

function add_sign_up_box(props) {
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={6}>
        <Card bordered={false} hoverable={true} onClick={() => {window.open("https://aws.amazon.com/marketplace/pp/prodview-qd42dvuchjxue", '_blank').focus()}}>
          <b>AWS Marketplace Page</b>
        </Card>
      </Col>
    )
  }
  return <></>
}

function add_docs_box(props) {
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={6} offset={1}>
        <Card bordered={false} hoverable={true} onClick={() => {window.open(docsEndpoint, '_blank').focus()}}>
          <b>Documentation</b>
        </Card>
      </Col>
    )
  }
  return (
    <Col span={6}>
      <Card bordered={false} hoverable={true} onClick={() => {window.open(docsEndpoint, '_blank').focus()}}>
        <b>Documentation</b>
      </Card>
    </Col>
  )
}

function add_home_page_box(props) {
  const prefix = environment !== "prod" ? environment + "." : ""
  console.log("setting a prefix of", prefix)
  return (
    <Col span={6} offset={1}>
      <Card bordered={false} hoverable={true} onClick={() => {window.open("http://" + prefix + "portal.skysaver.io/", '_blank').focus()}}>
        <b>Home Page</b>
      </Card>
    </Col>
  )
}

function get_grid_style(items, rows) {
  return {
    width: '100%',
    textAlign: 'center',
    padding: '4',
  }
}

function set_selected_project(selectedProjectFunc, project) {
  return () => {
    console.log(project)
    selectedProjectFunc(project.id)
  }
}

function generate_projects_grid(project_obj) {
  const gridStyle = get_grid_style(project_obj.userProjects.projects, 1)
  var ret = []
  console.log(project_obj.userProjects.projects)
  for (var i=0; i < project_obj.userProjects.projects.length; i++) {
    ret.push(<Card.Grid key={i} style={gridStyle} onClick={set_selected_project(project_obj.setSelectedProjectFunc, project_obj.userProjects.projects[i])}>
      {project_obj.userProjects.projects[i].name}
    </Card.Grid>)
  }
  return ret
}

function add_login_box(props, loginWithRedirect) {
  console.log("add_login_box")
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={8} offset={8}>
        <Card style={{height: "20vh", textAlign: "center", verticalAlign: "middle"}} bordered={false} hoverable={true} onClick={() => loginWithRedirect()}>
          <Meta title={<Title level={2}>Login</Title>} />
        </Card>
      </Col>
    )
  } else {
    console.log(props.common.project.selectedProject)
    if (props.common.project.selectedProject == null) {
      if (!!props.common.project.userProjects && props.common.project.userProjects.projects.length > 0) {
        return <Col span={8} offset={8}>
          <Card title={<Title level={4}>Select a Project</Title>} style={{overflow: "scroll", height: "20vh", textAlign: "center", verticalAlign: "middle"}} bordered={false} hoverable={false}>
            {generate_projects_grid(props.common.project)}
          </Card>
        </Col>
      }
    }
  }
  return <></>
}

function simple_homepage_data(props, loginWithRedirect) {
  return (
    <>
      <Row style={{height: "15vh"}}></Row>
      <Row style={{height: "25vh"}}>
        {add_login_box(props, loginWithRedirect)}
      </Row>
      <Row style={{height: "10vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        {add_sign_up_box(props)}
        {add_docs_box(props)}
        {add_home_page_box(props)}
      </Row>
      <Row style={{height: "20vh"}}></Row>
    </>
  )
}

function project_not_setup(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === false && proj.enabled
}

function display_project_still_setting_up(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This project is still being set up, please check back in few minutes
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_subscription_failure(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === false && proj.enabled === false
}

function display_subscription_failure(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        The AWS subscription failed to set up
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_unsubscribe_initiated(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === true && proj.enabled === false
}

function display_unsubscribed_initiated(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This projects subscription has been cancelled, we're currently cleaning up.
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_unsubscribed(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === true && proj.unsubscribed === true && proj.enabled === false
}

function display_unsubscribed(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This project has been successfully unsubscribed.
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

const Home = (props) => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  if (props.common.isLoading) {
    console.log("still loading the user data")
    return (<div>Loading ...</div>)
  }
  if (has_new_registree_parameter(searchParams)) {
    return (<SignupFlow common={props.common} registree_data={get_registree_data(searchParams)}/>)
  }
  if(project_not_setup(props)) {
    console.log("the project is not set up")
    setTimeout(
      retrieveUserOrgs,
      15000,
      props.common.api_token,
      props.common.oauth_id_token,
      props.common.project.setUserProjectsFunc
    )
    return display_project_still_setting_up(props)
  }
  if(project_subscription_failure(props)) {
    return display_subscription_failure(props)
  }
  if(project_unsubscribe_initiated(props)) {
    return display_unsubscribed_initiated(props)
  }
  if(project_unsubscribed(props)) {
    return display_unsubscribed(props)
  }
  return simple_homepage_data(props, loginWithRedirect)
}

export default Home;